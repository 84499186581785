import axios from 'axios'
import { Product } from 'models'
export const getProducts = async (
	searchTerm?: string,
	includeDeleted?: boolean
) => {
	try {
		let url = '/products'
		const queryParams = []

		if (searchTerm) {
			queryParams.push(`search=${encodeURIComponent(searchTerm)}`)
		}
		if (includeDeleted) {
			queryParams.push('includeDeleted=true')
		}

		if (queryParams.length) {
			url += '?' + queryParams.join('&')
		}

		const response = await axios.get(url)
		return response
	} catch (error: any) {
		return error.response
	}
}

export const createProduct = async (productData: Product) => {
	try {
		const response = await axios.post(`/products`, { ...productData })
		return response
	} catch (error: any) {
		return error.response
	}
}

export const cloneProduct = async (productId: string) => {
	try {
		const response = await axios.post(`/products/clone`, { productId })
		return response
	} catch (error: any) {
		return error.response
	}
}

export const deleteProduct = async (productIds: string[]) => {
	try {
		const response = await axios.put('/products/delete', { productIds })
		return response
	} catch (error: any) {
		return error.response
	}
}

export const recoverProduct = async (productId: string) => {
	try {
		const response = await axios.put('/products/recover', { productId })
		return response
	} catch (error: any) {
		return error.response
	}
}

export const updateProduct = async (productData: any) => {
	try {
		const { id, ...rest } = productData
		const response = await axios.put(`/products/${id}`, {
			...rest
		})
		return response
	} catch (error: any) {
		return error.response
	}
}

export const getProductOptions = async () => {
	try {
		const response = await axios.get(`/products/options`)
		return response
	} catch (error: any) {
		return error.response
	}
}
