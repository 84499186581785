import axios from 'axios'
import { Heating } from 'models'

export const getHeatings = async (searchTerm?: string) => {
	let url = '/heating'
	if (searchTerm) {
		url += `?search=${encodeURIComponent(searchTerm)}`
	}
	const response = await axios.get(url)
	return response.data
}

export const createHeating = async (heatingData: Heating) => {
	try {
		const response = await axios.post(`/heating`, {
			...heatingData
		})
		return response.data
	} catch (error: any) {
		return error.response
	}
}

export const updateHeating = async (heatingData: any) => {
	try {
		const { id, ...rest } = heatingData
		const response = await axios.put(`/heating/${id}`, {
			...rest
		})
		return response.data
	} catch (error: any) {
		return error.response
	}
}

export const deleteHeating = async (heatingId: number) => {
	try {
		const response = await axios.delete(`/heating/${heatingId}`)
		return response.data
	} catch (error: any) {
		return error.response
	}
}
