import React, { lazy, useEffect, useState } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { setAuthToken } from 'services'
import { UserContextProvider } from 'contexts/me'
import SuperAdminRouteWrapper from './SuperAdminRouteWrapper'

const Navigation = lazy(() => import('components/Navigation'))
const Projects = lazy(() => import('pages/Projects'))
const ProjectAudit = lazy(() => import('pages/ProjectAudit'))
const Products = lazy(() => import('pages/Products'))
const Lookups = lazy(() => import('pages/Lookups'))
const LightingExchangeForm = lazy(
	() => import('pages/ProjectAudit/LightingExchange/LightingExchangeForm')
)
const ProjectStatus = lazy(() => import('pages/Lookups/ProjectStatus'))
const Category = lazy(() => import('pages/Lookups/Category'))
const PaymentTerms = lazy(() => import('pages/Lookups/PaymentTerms'))
const ColourTemperature = lazy(() => import('pages/Lookups/ColourTemperature'))
const Fixture = lazy(() => import('pages/Lookups/Fixture'))
const Lamp = lazy(() => import('pages/Lookups/Lamp'))
const Technology = lazy(() => import('pages/Lookups/Technology'))
const Voltage = lazy(() => import('pages/Lookups/Voltage'))
const BaseType = lazy(() => import('pages/Lookups/BaseType'))
const Heating = lazy(() => import('pages/Lookups/Heating'))
const SalesRep = lazy(() => import('pages/Lookups/SalesRep'))

const PrivateRoutes = (): React.ReactElement => {
	const [isAuthenticated, setIsAuthenticated] = useState(false)
	useEffect(() => {
		setAuthToken(() => setIsAuthenticated(true))
	}, [])
	if (!isAuthenticated) {
		return <></>
	}

	return (
		<UserContextProvider>
			<Routes>
				<Route path='/' element={<Navigation />}>
					<Route index element={<Projects />} />
					<Route path='projects' element={<Navigate to='/' />} />
					<Route path='projects/:projectId' element={<ProjectAudit />} />
					<Route
						path='projects/:projectId/lighting-exchange/:exchangeId?'
						element={<LightingExchangeForm />}
					/>

					<Route element={<SuperAdminRouteWrapper />}>
						<Route path='products' element={<Products />} />
						<Route path='lookups' element={<Lookups />} />
						<Route path='/project-status' element={<ProjectStatus />} />
						<Route path='/category' element={<Category />} />
						<Route path='/payment-terms' element={<PaymentTerms />} />
						<Route path='/colour-temperature' element={<ColourTemperature />} />
						<Route path='/fixture' element={<Fixture />} />
						<Route path='/lamp' element={<Lamp />} />
						<Route path='/technology' element={<Technology />} />
						<Route path='/voltage' element={<Voltage />} />
						<Route path='/base-type' element={<BaseType />} />
						<Route path='/heating' element={<Heating />} />
						<Route path='/sales-rep' element={<SalesRep />} />
					</Route>

					<Route path='*' element={<Navigate to='/' replace />} />
				</Route>
			</Routes>
		</UserContextProvider>
	)
}

export default PrivateRoutes
