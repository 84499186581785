import React from 'react'
import {
	Grid,
	InputLabel,
	Checkbox as MuiCheckbox,
	CheckboxProps
} from '@mui/material'

interface Props extends CheckboxProps {
	label: any
	required?: boolean
	error?: any
	justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'space-between'
	type?: 'normal' | 'consent'
	dense?: boolean
}
const Checkbox = ({
	label,
	required,
	error,
	justifyContent,
	type = 'normal',
	dense,
	...others
}: Props) => {
	if (type === 'normal')
		return (
			<Grid container justifyContent={justifyContent ?? 'center'}>
				<InputLabel
					sx={{
						fontSize: 14,
						fontWeight: 'bold',
						color: error ? '#dc3545' : '#000'
					}}
					required={required}
				>
					{label}
				</InputLabel>
				<Grid container justifyContent={justifyContent ?? 'center'}>
					<MuiCheckbox
						checked={others.checked}
						onChange={others.onChange}
						sx={{ color: error ? '#dc3545' : '#000', padding: '9px 0' }}
						{...others}
					/>
				</Grid>
			</Grid>
		)
	return (
		<Grid
			container
			alignItems='center'
			justifyContent='flex-start'
			sx={{ marginBottom: dense ? '' : '16px' }}
		>
			<MuiCheckbox
				sx={{ padding: '0' }}
				checked={others.checked}
				onChange={others.onChange}
				{...others}
			/>
			<InputLabel
				sx={{
					fontSize: 14
				}}
				disabled={others.disabled}
			>
				{label}
			</InputLabel>
		</Grid>
	)
}

export default Checkbox
