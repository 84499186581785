import { UserContext } from 'contexts/me'
import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const SuperAdminRouteWrapper = () => {
	const { isSuperAdmin } = useContext(UserContext)
	return isSuperAdmin ? <Outlet /> : <Navigate to='/' replace />
}

export default SuperAdminRouteWrapper
