import axios from 'axios'
import { Lamp } from 'models'

export const getLamps = async (searchTerm?: string) => {
	let url = '/lamp'
	if (searchTerm) {
		url += `?search=${encodeURIComponent(searchTerm)}`
	}
	const response = await axios.get(url)
	return response.data
}

export const createLamp = async (lampData: Lamp) => {
	try {
		const response = await axios.post(`/lamp`, {
			...lampData
		})
		return response.data
	} catch (error: any) {
		return error.response
	}
}

export const updateLamp = async (lampData: any) => {
	try {
		const { id, ...rest } = lampData
		const response = await axios.put(`/lamp/${id}`, {
			...rest
		})
		return response.data
	} catch (error: any) {
		return error.response
	}
}

export const deleteLamp = async (lampId: number) => {
	try {
		const response = await axios.delete(`/lamp/${lampId}`)
		return response.data
	} catch (error: any) {
		return error.response
	}
}
