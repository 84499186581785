import {
	getProjectOptions,
	getLightingOptions,
	getProducts,
	getProductOptions
} from 'services'

export const loadProjectOptions = async (
	toggleOptionsLoading: any,
	setOptions: any
) => {
	toggleOptionsLoading(true)
	const res = await getProjectOptions()
	if (res && res.status === 200) {
		const { data } = res
		setOptions(data)
	}
	toggleOptionsLoading(false)
}

export const loadLightingOptions = async (
	toggleOptionsLoading: any,
	setOptions: any,
	setDefaults?: (defaultValues: any) => void
) => {
	let productOptions, lightingOptions, optionDefaultValues
	toggleOptionsLoading(true)
	const productResult = await getProducts()
	if (productResult.status === 200) {
		productOptions = productResult.data
	}
	const optionResult = await getLightingOptions()
	if (optionResult?.status === 200) {
		const { optionDefaults, ...restOfResult } = optionResult.data
		optionDefaultValues = optionDefaults
		lightingOptions = restOfResult
	}
	setOptions({ productOptions, ...lightingOptions })

	if (setDefaults) {
		const {
			defaultCurrentFixture,
			defaultCurrentFixtureId,
			defaultCurrentLamp,
			defaultCurrentLampId,
			defaulCurrentTechnologyId,
			defaultCurrentBaseTypeId,
			defaultCurrentColourTemperatureId,
			defaultCurrentVoltageId,
			defaultCurrentFixtureHeight,
			defaultCurrentWattagePerLamp,
			defaultCurrentPrice
		} = optionDefaultValues
		const defaultValues = {
			currentFixture: defaultCurrentFixture,
			currentFixtureId: defaultCurrentFixtureId,
			currentLamp: defaultCurrentLamp,
			currentLampId: defaultCurrentLampId,
			currentTechnologyId: defaulCurrentTechnologyId,
			currentBaseTypeId: defaultCurrentBaseTypeId,
			currentColourTemperatureId: defaultCurrentColourTemperatureId,
			currentVoltageId: defaultCurrentVoltageId,
			currentFixtureHeight: defaultCurrentFixtureHeight,
			currentWattagePerLamp: defaultCurrentWattagePerLamp,
			currentPrice: defaultCurrentPrice
		}
		setDefaults({ ...defaultValues })
	}
	toggleOptionsLoading(false)
}

export const loadProductOptions = async (
	toggleOptionsLoading: any,
	setOptions: any
) => {
	toggleOptionsLoading(true)
	const res = await getProductOptions()
	if (res && res.status === 200) {
		const { data } = res
		setOptions(data)
	}
	toggleOptionsLoading(false)
}
