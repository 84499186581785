import axios from 'axios'
import { ColourTemperature } from 'models'

export const getColourTemperatures = async (searchTerm?: string) => {
	let url = '/colour-temperature'
	if (searchTerm) {
		url += `?search=${encodeURIComponent(searchTerm)}`
	}
	const response = await axios.get(url)
	return response.data
}
export const createColourTemperature = async (
	colourTemperatureData: ColourTemperature
) => {
	try {
		const response = await axios.post(`/colour-temperature`, {
			...colourTemperatureData
		})
		return response.data
	} catch (error: any) {
		return error.response
	}
}

export const updateColourTemperature = async (colourTemperatureData: any) => {
	try {
		const { id, ...rest } = colourTemperatureData
		const response = await axios.put(`/colour-temperature/${id}`, {
			...rest
		})
		return response.data
	} catch (error: any) {
		return error.response
	}
}

export const deleteColourTemperature = async (colourTemperatureId: number) => {
	try {
		const response = await axios.delete(
			`/colour-temperature/${colourTemperatureId}`
		)
		return response.data
	} catch (error: any) {
		return error.response
	}
}
