import React, { Fragment, useContext } from 'react'
import { Outlet, Link } from 'react-router-dom'
import {
	Grid,
	GridProps,
	Typography,
	TypographyProps,
	styled,
	Button
} from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import { handleLogout } from 'utils'
import { UserContext } from 'contexts/me'

const NavigationContainer = styled(Grid)<GridProps>(() => ({
	alignItems: 'center',
	justifyContent: 'space-between',
	borderBottom: '1.5px solid #E7E7E7',
	boxShadow: 'inset 0 1px 0 rgba(255,255,255,.15),0 1px 5px rgba(0,0,0,.075)'
}))

const NavigationText = styled(Typography)<TypographyProps>(() => ({
	'&:hover': { color: '#333' },
	padding: '15px'
}))

const Navigation = () => {
	const { user, isSuperAdmin } = useContext(UserContext)

	return (
		<Fragment>
			<NavigationContainer container>
				<Link to='/'>
					<img
						src={'/images/nichent_logo.png'}
						alt={'nichent_logo'}
						style={{ height: 50, padding: '0px 15px' }}
					/>
				</Link>
				<Grid item xs container justifyContent='flex-start'>
					<Link to='/projects'>
						<NavigationText variant='subtitle1'>Projects</NavigationText>
					</Link>
					{isSuperAdmin && (
						<>
							<Link to='/products'>
								<NavigationText variant='subtitle1'>Products</NavigationText>
							</Link>
							<Link to='/lookups'>
								<NavigationText variant='subtitle1'>Lookups</NavigationText>
							</Link>
						</>
					)}
				</Grid>
				<Grid item xs container justifyContent='flex-end' alignItems='center'>
					<NavigationText
						variant='subtitle1'
						alignItems={'center'}
						display={'flex'}
					>
						<PersonIcon />
						{user?.fullName || ''}
					</NavigationText>
					<Button variant='text' onClick={handleLogout}>
						<NavigationText variant='subtitle1'>Logout</NavigationText>
					</Button>
				</Grid>
			</NavigationContainer>
			<Outlet />
		</Fragment>
	)
}

export default Navigation
