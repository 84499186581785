type Order = 'asc' | 'desc'

const descendingComparator = (a: any, b: any, orderBy: any) => {
	if (a[orderBy] === null && b[orderBy] === null) {
		return 0
	}
	if (a[orderBy] === null) {
		return 1
	}
	if (b[orderBy] === null) {
		return -1
	}

	const lowerA =
		typeof a[orderBy] === 'string'
			? a[orderBy].trim().toLowerCase()
			: a[orderBy]
	const lowerB =
		typeof b[orderBy] === 'string'
			? b[orderBy].trim().toLowerCase()
			: b[orderBy]
	if (lowerB < lowerA) {
		return -1
	}
	if (lowerB > lowerA) {
		return 1
	}
	return 0
}

export const getComparator = <Key extends keyof any>(
	order: Order,
	orderBy: Key
): ((
	a: Record<Key, number | string>,
	b: Record<Key, number | string>
) => number) =>
	order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy)

export const stableSort = (
	array: readonly any[],
	comparator: (a: any, b: any) => number
) => {
	if (!array) {
		return []
	}
	const stabilizedData = array.map(
		(element, index) => [element, index] as [any, number]
	)
	stabilizedData.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) {
			return order
		}
		return a[1] - b[1]
	})
	return stabilizedData.map((element: any) => element[0])
}
