import React, { lazy, Suspense } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate
} from '@azure/msal-react'
import PrivateRoutes from 'routes/PrivateRoutes'

const Login = lazy(() => import('pages/Login'))

const App = (): React.ReactElement => {
	return (
		<Suspense fallback={<CircularProgress />}>
			<AuthenticatedTemplate>
				<PrivateRoutes />
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<Login />
			</UnauthenticatedTemplate>
		</Suspense>
	)
}

export default App
