import axios from 'axios'
import { ProjectCreateState } from 'models'

export const getProjects = async (
	searchTerm?: string,
	includeDeleted?: boolean
) => {
	try {
		let url = '/projects'
		const queryParams = []

		if (searchTerm) {
			queryParams.push(`search=${encodeURIComponent(searchTerm)}`)
		}
		if (includeDeleted) {
			queryParams.push('includeDeleted=true')
		}
		if (queryParams.length) {
			url += '?' + queryParams.join('&')
		}

		const response = await axios.get(url)
		return response
	} catch (error: any) {
		return error.response
	}
}

export const getProjectById = async (projectId: string) => {
	try {
		const response = await axios.get(`/projects/${projectId}`)
		return response
	} catch (error: any) {
		return error.response
	}
}

export const getProjectOptions = async () => {
	try {
		const response = await axios.get(`/projects/options`)
		return response
	} catch (error: any) {
		return error.response
	}
}

export const createProject = async (projectData: ProjectCreateState) => {
	try {
		const response = await axios.post(`/projects`, { ...projectData })
		return response
	} catch (error: any) {
		return error.response
	}
}

export const cloneProject = async (projectId: string) => {
	try {
		const response = await axios.post(`/projects/clone/${projectId}`)
		return response
	} catch (error: any) {
		return error.response
	}
}

export const deleteProject = async (projectId: string) => {
	try {
		const response = await axios.delete(`/projects/${projectId}`)
		return response
	} catch (error: any) {
		return error.response
	}
}

export const recoverProject = async (projectId: string) => {
	try {
		const response = await axios.put(`/projects/recover/${projectId}`)
		return response
	} catch (error: any) {
		return error.response
	}
}

export const updateProject = async (projectData: any) => {
	try {
		const {
			id,
			jobName,
			invoiceNum,
			paymentTermsId,
			jobAddress,
			company,
			firstName,
			lastName,
			phone,
			email,
			salesRepId,
			projectStatusId,
			fixedAnnualMaintenanceCost,
			miscInstallCost,
			discount,
			taxRate,
			ratePerKwh,
			rebateIncentive,
			note,
			warrantyStartDate
		} = projectData

		const response = await axios.put(`/projects/${id}`, {
			jobName,
			invoiceNum,
			paymentTermsId,
			jobAddress,
			company,
			firstName,
			lastName,
			phone,
			email,
			salesRepId,
			projectStatusId,
			fixedAnnualMaintenanceCost,
			miscInstallCost,
			discount,
			taxRate,
			ratePerKwh,
			rebateIncentive,
			note,
			warrantyStartDate
		})
		return response
	} catch (error: any) {
		return error.response
	}
}

export const createOrUpdateRebateApplication = async (
	projectId: string,
	rebateApplication: Object
) => {
	try {
		const response = await axios.put(
			`/projects/rebate-application/${projectId}`,
			{
				...rebateApplication
			}
		)
		return response
	} catch (error: any) {
		return error.response
	}
}
