import React, { Fragment } from 'react'
import {
	Table as MuiTable,
	TableBody,
	TableContainer,
	TableRow,
	Box,
	TableHead,
	TableSortLabel,
	Grid,
	Button,
	Typography
} from '@mui/material'
import { Order } from 'models'
import { getComparator, stableSort } from 'utils'
import TableCell from './TableCell'
import { Add as AddIcon } from '@mui/icons-material'
import TextField from '../TextField'

interface TableProps {
	data: any
	tableHeads: any[]
	TableRowBuilder: any
	order: Order
	orderBy: any
	handleRequestSort: any
	search: any
	setSearch: any
	handleClickNew: any
	colSpan: number
}

const LookupTable = ({
	data,
	tableHeads,
	TableRowBuilder,
	order,
	orderBy,
	handleRequestSort,
	search,
	setSearch,
	handleClickNew,
	colSpan
}: TableProps) => {
	const rows = React.useMemo(
		() => stableSort(data, getComparator(order, orderBy)),
		[data, order, orderBy]
	)
	return (
		<Fragment>
			<Grid container justifyContent={'space-between'}>
				<Box sx={{ display: 'flex' }}>
					<Button variant='contained' onClick={() => handleClickNew()}>
						<AddIcon sx={{ width: '16px' }} />
						New
					</Button>
				</Box>
				<TextField
					value={search}
					label='Search:'
					handleChange={(input: any) => setSearch(input)}
				/>
			</Grid>
			<TableContainer component={Box}>
				<MuiTable aria-label='project table'>
					<TableHead>
						<TableRow>
							{tableHeads.map((row: any, index: number) => {
								return (
									<TableCell
										dense
										key={`table_head-${index}`}
										align={row.align}
										width={row.width}
										sx={{ whiteSpace: 'nowrap' }}
									>
										{row.sortable ? (
											<TableSortLabel
												active={orderBy === row.id}
												direction={orderBy === row.id ? order : 'asc'}
												onClick={e => handleRequestSort(e, row.id)}
											>
												{row.name}
											</TableSortLabel>
										) : (
											row.name
										)}
									</TableCell>
								)
							})}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows && rows.length > 0 ? (
							rows.map((row: any, index: number) => (
								<TableRowBuilder key={`table_row_builder-${index}`} row={row} />
							))
						) : (
							<TableRow>
								<TableCell dense colSpan={colSpan}>
									<Typography align='center'>
										No matching records found
									</Typography>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</MuiTable>
			</TableContainer>
		</Fragment>
	)
}

export default LookupTable
