import React from 'react'
import { CircularProgress, Grid } from '@mui/material'

interface props {
	minHeight?: any
}
const LoadingIndicator = ({ minHeight }: props) => {
	const NavigationHeight = 148
	return (
		<Grid
			container
			spacing={2}
			justifyContent={'center'}
			alignItems={'center'}
			sx={{ minHeight: minHeight ?? `calc(100vh - ${NavigationHeight}px)` }}
		>
			<CircularProgress />
		</Grid>
	)
}

export default LoadingIndicator
