import React, { ReactNode } from 'react'
import { Button, ButtonProps, CircularProgress } from '@mui/material'

interface LoadingButtonProps extends ButtonProps {
	loading: boolean
	children: ReactNode
}
const LoadingButton = ({
	loading,
	children,
	...others
}: LoadingButtonProps) => {
	return (
		<Button
			disabled={loading}
			endIcon={
				loading ? <CircularProgress size={12} sx={{ color: 'white' }} /> : <></>
			}
			{...others}
		>
			{children}
		</Button>
	)
}

export default LoadingButton
