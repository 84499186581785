import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import App from './App'
import { theme } from 'components'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'

import 'bootstrap/dist/css/bootstrap.min.css'

//msal
import {
	PublicClientApplication,
	EventType,
	EventMessage,
	AuthenticationResult
} from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from './authConfig'

export const msalInstance = new PublicClientApplication(msalConfig)

msalInstance.addEventCallback((event: EventMessage) => {
	if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
		const payload = event.payload as AuthenticationResult
		const account = payload.account
		msalInstance.setActiveAccount(account)
	}
})
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
	<MsalProvider instance={msalInstance}>
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</ThemeProvider>
		</StyledEngineProvider>
	</MsalProvider>
)
