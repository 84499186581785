import { TableRow as MuiTableRow, styled } from '@mui/material'

const TableRow = styled(MuiTableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: '#F9F9F9'
	},
	'&:last-child td, &:last-child th': {
		border: 0
	},
	'&:hover': { backgroundColor: '#c4e3f3' }
}))

export default TableRow
