import React from 'react'
import {
	TablePagination as MuiTablePagination,
	Box,
	IconButton
} from '@mui/material'
import {
	FirstPage as FirstPageIcon,
	KeyboardArrowLeft as KeyboardArrowLeftIcon,
	KeyboardArrowRight as KeyboardArrowRightIcon,
	LastPage as LastPageIcon
} from '@mui/icons-material'

interface TablePaginationActionsProps {
	count: number
	page: number
	rowsPerPage: number
	onPageChange: any
}

interface TablePaginationProps extends TablePaginationActionsProps {
	colSpan: number
	onRowsPerPageChange: any
}

const TablePaginationActions = (props: TablePaginationActionsProps) => {
	const { count, page, rowsPerPage, onPageChange } = props

	const handleFirstPageButtonClick = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		onPageChange(event, 0)
	}

	const handleBackButtonClick = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		onPageChange(event, page - 1)
	}

	const handleNextButtonClick = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		onPageChange(event, page + 1)
	}

	const handleLastPageButtonClick = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
	}

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label='first page'
			>
				<FirstPageIcon color='primary' />
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label='previous page'
			>
				<KeyboardArrowLeftIcon color='primary' />
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label='next page'
			>
				<KeyboardArrowRightIcon color='primary' />
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label='last page'
			>
				<LastPageIcon color='primary' />
			</IconButton>
		</Box>
	)
}

const TablePagination = (props: TablePaginationProps) => {
	const {
		colSpan,
		count,
		rowsPerPage,
		page,
		onPageChange,
		onRowsPerPageChange
	} = props
	return (
		<MuiTablePagination
			rowsPerPageOptions={[10, 25, 50, 100, { label: 'All', value: -1 }]}
			colSpan={colSpan}
			count={count}
			rowsPerPage={rowsPerPage}
			page={page}
			SelectProps={{
				inputProps: {
					'aria-label': 'rows per page'
				},
				native: true
			}}
			onPageChange={onPageChange}
			onRowsPerPageChange={onRowsPerPageChange}
			ActionsComponent={TablePaginationActions}
		/>
	)
}
export default TablePagination
