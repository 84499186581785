import { createTheme } from '@mui/material'
const primaryColor = '#2a6496'
const textPrimary = '#333'
const textSecondary = '#777'

const theme = createTheme({
	palette: {
		primary: {
			main: primaryColor
		}
	},
	typography: {
		fontFamily: ['Helvetica Neue'].join(','),
		h1: {
			fontFamily: 'Helvetica Neue',
			color: textPrimary,
			fontSize: 21,
			fontWeight: 400
		},
		h2: {
			fontFamily: 'Helvetica Neue',
			color: textPrimary,
			fontSize: 18,
			fontWeight: 700
		},
		h3: {
			fontFamily: 'Helvetica Neue',
			color: 'white',
			fontSize: 17,
			fontWeight: 500,
			lineHeight: '17px'
		},
		h4: {
			fontFamily: 'Helvetica Neue',
			fontSize: 14,
			fontWeight: 'bold'
		},
		subtitle1: {
			fontFamily: 'Helvetica Neue',
			color: textSecondary,
			fontSize: 14,
			lineHeight: '20px',
			fontWeight: 500
		},
		body1: {
			fontFamily: 'Helvetica Neue',
			color: textPrimary,
			fontSize: 14,
			lineHeight: '20px'
		},
		button: {
			fontFamily: 'Helvetica Neue',
			color: textSecondary,
			fontSize: 14,
			lineHeight: '20px',
			fontWeight: 500,
			textTransform: 'none'
		}
	},
	components: {
		MuiOutlinedInput: {
			styleOverrides: {
				input: {
					padding: '6px 12px'
				},
				notchedOutline: {
					border: '1px solid #ccc',
					borderRadius: '4px',
					boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)'
				}
			}
		},
		MuiButton: {
			defaultProps: {
				disableElevation: true
			},
			styleOverrides: {
				textPrimary: {
					'&:hover': { backgroundColor: 'white' }
				},
				containedPrimary: {
					minWidth: 50,
					height: 27,
					padding: '1px',
					backgroundImage: 'linear-gradient(to bottom,#428bca 0,#2d6ca2 100%)',
					border: '1px solid #2b669a',
					boxShadow:
						'inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(0,0,0,.075)',
					textShadow: '0 -1px 0 rgba(0,0,0,.2)',
					'&:hover': {
						backgroundColor: '#2d6ca2'
					},
					'&:disabled': {
						color: 'white !important',
						backgroundColor: '#2d6ca2',
						backgroundImage: 'none',
						border: '1px solid #357ebd',
						opacity: 0.65
					}
				},
				containedSizeSmall: {
					height: 22,
					padding: '3px',
					minWidth: 50,
					fontSize: 13
				},
				containedSecondary: {
					color: '#333',
					padding: '6px 12px',
					backgroundImage: 'linear-gradient(to bottom,#fff 0,#e0e0e0 100%)',
					border: '1px solid #ccc',
					boxShadow:
						'inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(0,0,0,.075)',
					textShadow: '0 1px 0 #fff',
					'&:hover': {
						borderColor: '#dbdbdb',
						backgroundColor: '#e0e0e0'
					}
				},
				containedSuccess: {
					backgroundImage: 'none',
					backgroundColor: '#5cb85c',
					borderColor: '#4cae4c',
					'&:hover': {
						backgroundColor: '#449d44'
					}
				},
				containedError: {
					backgroundImage: 'none',
					backgroundColor: '#d9534f',
					borderColor: '#d43f3a',
					'&:hover': {
						backgroundColor: '#c9302c'
					},
					'&:disabled': {
						color: 'white !important',
						backgroundImage: 'none',
						backgroundColor: '#d9534f',
						borderColor: '#d43f3a',
						opacity: 0.65
					}
				},
				endIcon: {
					marginLeft: '2px',
					marginRight: '1px'
				}
			}
		},
		MuiTablePagination: {
			styleOverrides: {
				selectLabel: {
					margin: 0
				},
				displayedRows: {
					margin: 0
				}
			}
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					'&.Mui-selected': {
						backgroundColor: '#c4e3f3',
						'&:hover': {
							backgroundColor: '#c4e3f3'
						}
					}
				}
			}
		},
		MuiDialog: {
			styleOverrides: {
				paper: {
					borderRadius: 6,
					minWidth: '500px'
				}
			}
		},
		MuiDialogActions: {
			styleOverrides: {
				root: {
					padding: '16px'
				}
			}
		},
		MuiAutocomplete: {
			styleOverrides: {
				root: {
					'& .MuiOutlinedInput-root': {
						padding: '6px 12px'
					},
					'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
						padding: '0px'
					}
				}
			}
		},
		MuiAlert: {
			styleOverrides: {
				outlined: {
					padding: '0px 16px'
				}
			}
		}
	}
})

export default theme
