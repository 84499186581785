import React from 'react'
import {
	styled,
	InputLabel,
	OutlinedInputProps,
	OutlinedInput
} from '@mui/material'
import { toFixedValue } from 'utils'

interface Props extends OutlinedInputProps {
	disableFullWidth?: boolean
	handleChange: any
	handleOnBlur?: any
}

const StyledTextField = styled(OutlinedInput)<OutlinedInputProps>(() => ({
	marginTop: '5px',
	marginBottom: '15px'
}))

const TextField = ({
	value,
	label,
	required,
	disableFullWidth,
	error,
	type,
	handleChange,
	handleOnBlur,
	...others
}: Props) => {
	return (
		<div>
			{label && (
				<InputLabel
					sx={{
						fontSize: 14,
						fontWeight: 'bold',
						color: error ? '#dc3545' : '#000'
					}}
					required={required}
				>
					{label}
				</InputLabel>
			)}
			<StyledTextField
				value={value ?? ''}
				fullWidth={!disableFullWidth}
				error={error}
				inputProps={{ inputMode: type === 'numeric' ? 'numeric' : 'text' }}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
					const input = event.target.value
					if (type === 'numeric' || type === 'decimal') {
						const numericValue = input.replace(/[^0-9.]/g, '')
						// Ensure only one decimal point is allowed
						const decimalCount = numericValue.split('.').length - 1
						if (decimalCount > 1) {
							return
						}
						handleChange(numericValue)
					} else {
						handleChange(input)
					}
				}}
				onBlur={event => {
					if (type === 'decimal') {
						const parsedValue = parseFloat(event.target.value)
						if (!isNaN(parsedValue)) {
							const formattedValue = toFixedValue(parsedValue)
							handleChange(formattedValue)
						}
					}
					if (handleOnBlur) {
						const value = event.target.value
						handleOnBlur(value)
					}
				}}
				{...others}
			/>
		</div>
	)
}

export default TextField
