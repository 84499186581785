import React from 'react'
import {
	InputLabel,
	Autocomplete as MuiAutocomplete,
	TextField
} from '@mui/material'

interface Props {
	label: string
	loading: boolean
	options: any
	value: any
	getOptionLabel: any
	getOptionKey?: any
	onChange: any
	required?: boolean
	error?: any
}
const Autocomplete = ({
	label,
	loading,
	options,
	value,
	getOptionLabel,
	getOptionKey,
	onChange,
	required,
	error
}: Props) => {
	return (
		<div>
			<InputLabel
				sx={{
					fontSize: 14,
					fontWeight: 'bold',
					color: error ? '#dc3545' : '#000'
				}}
				required={required}
			>
				{label}
			</InputLabel>
			<MuiAutocomplete
				onChange={onChange}
				loading={loading}
				value={value ?? null}
				options={options ?? []}
				getOptionLabel={getOptionLabel}
				getOptionKey={getOptionKey}
				renderInput={params => <TextField error={error} {...params} />}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				sx={{ marginTop: '5px', marginBottom: '15px' }}
			/>
		</div>
	)
}

export default Autocomplete
