import axios from 'axios'
import { PaymentTerms } from 'models'

export const getPaymentTerms = async (searchTerm?: string) => {
	let url = '/payment-terms'
	if (searchTerm) {
		url += `?search=${encodeURIComponent(searchTerm)}`
	}
	const response = await axios.get(url)
	return response.data
}

export const createPaymentTerms = async (paymentTermsData: PaymentTerms) => {
	try {
		const response = await axios.post(`/payment-terms`, {
			...paymentTermsData
		})
		return response.data
	} catch (error: any) {
		return error.response
	}
}

export const updatePaymentTerms = async (paymentTermsData: any) => {
	try {
		const { id, ...rest } = paymentTermsData
		const response = await axios.put(`/payment-terms/${id}`, {
			...rest
		})
		return response.data
	} catch (error: any) {
		return error.response
	}
}

export const deletePaymentTerms = async (paymentTermsId: number) => {
	try {
		const response = await axios.delete(`/payment-terms/${paymentTermsId}`)
		return response.data
	} catch (error: any) {
		return error.response
	}
}
