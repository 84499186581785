import React from 'react'
import {
	Dialog,
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Alert
} from '@mui/material'

interface Props {
	open: boolean
	setOpen: any
	handleConfirmAction: any
	title?: string
	warning?:any
	confirm?:any
}
const ConfirmAction = ({
	title,
	open,
	setOpen,
	handleConfirmAction,
	warning,
	confirm
}: Props) => {
	const handleClose = (e: any) => {
		e.stopPropagation()
		setOpen(false)
	}
	const handleSubmit = async (e: any) => {
		e.stopPropagation()
		handleConfirmAction()
	}

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle
				sx={{ borderBottom: '1px solid #e5e5e5', marginBottom: '15px' }}
			>
				Are you sure?
			</DialogTitle>
			<DialogContent>
				{warning ? (warning):(<Alert severity='error' sx={{ width: '450px' }}>
					{`Deleting a ${title} will not affect previous
					audits,`}
					but it will <strong>no longer be available.</strong>
				</Alert>)}
			</DialogContent>
			<DialogActions
				sx={{ borderTop: '1px solid #e5e5e5', justifyContent: 'space-between' }}
			>
				<Button
					color='secondary'
					variant='contained'
					onClick={handleClose}
					sx={{
						height: '34px',
						padding: '12px 6px'
					}}
				>
					Cancel
				</Button>
				<Button
					variant='contained'
					onClick={handleSubmit}
					sx={{ height: '34px', padding: '12px 6px' }}
				>
					{confirm ?? 'Confirm'}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default ConfirmAction
