import React from 'react'
import { Grid, Typography } from '@mui/material'

interface Props {
	name?: string
	minHeight?: any
}
const NotFound = ({ name, minHeight }: Props) => {
	const NavigationHeight = 148
	return (
		<Grid
			container
			spacing={2}
			justifyContent={'center'}
			alignItems={'center'}
			sx={{ minHeight: minHeight ?? `calc(100vh - ${NavigationHeight}px)` }}
		>
			<Typography fontSize={20} fontWeight={500}>
				{`${
					name ?? ''
				} data does not exist or you dont have permission to access.`}
			</Typography>
		</Grid>
	)
}

export default NotFound
