import axios from 'axios'
import { Fixture } from 'models'

export const getFixtures = async (searchTerm?: string) => {
	let url = '/fixture'
	if (searchTerm) {
		url += `?search=${encodeURIComponent(searchTerm)}`
	}
	const response = await axios.get(url)
	return response.data
}
export const createFixture = async (fixtureData: Fixture) => {
	try {
		const response = await axios.post(`/fixture`, {
			...fixtureData
		})
		return response.data
	} catch (error: any) {
		return error.response
	}
}

export const updateFixture = async (fixtureData: any) => {
	try {
		const { id, ...rest } = fixtureData
		const response = await axios.put(`/fixture/${id}`, {
			...rest
		})
		return response.data
	} catch (error: any) {
		return error.response
	}
}

export const deleteFixture = async (fixtureId: number) => {
	try {
		const response = await axios.delete(`/fixture/${fixtureId}`)
		return response.data
	} catch (error: any) {
		return error.response
	}
}
