import axios, {
	AxiosRequestConfig,
	AxiosRequestHeaders,
	AxiosResponse,
	AxiosError
} from 'axios'
import { getAccessToken, handleLogout } from 'utils'
import { me } from './me'
import {
	getProjects,
	getProjectById,
	getProjectOptions,
	createProject,
	cloneProject,
	deleteProject,
	recoverProject,
	updateProject,
	createOrUpdateRebateApplication
} from './project'
import {
	getLightingExchangeById,
	getLightingOptions,
	createLightingExchange,
	cloneLightingExchange,
	deleteLightingExchange,
	updateLightingExchange
} from './lightingExchange'
import {
	getProducts,
	createProduct,
	cloneProduct,
	deleteProduct,
	recoverProduct,
	updateProduct,
	getProductOptions
} from './product'
import {
	getProjectStatus,
	createProjectStatus,
	updateProjectStatus,
	deleteProjectStatus,
	getCategories,
	createCategory,
	updateCategory,
	deleteCategory,
	getPaymentTerms,
	createPaymentTerms,
	updatePaymentTerms,
	deletePaymentTerms,
	getColourTemperatures,
	createColourTemperature,
	updateColourTemperature,
	deleteColourTemperature,
	getFixtures,
	createFixture,
	updateFixture,
	deleteFixture,
	getLamps,
	createLamp,
	updateLamp,
	deleteLamp,
	getTechnologies,
	createTechnology,
	updateTechnology,
	deleteTechnology,
	getVoltages,
	createVoltage,
	updateVoltage,
	deleteVoltage,
	getBaseTypes,
	createBaseType,
	updateBaseType,
	deleteBaseType,
	getHeatings,
	createHeating,
	updateHeating,
	deleteHeating,
	getSalesReps,
	createSalesRep,
	updateSalesRep,
	deleteSalesRep
} from './lookup'

interface AdaptAxiosRequestConfig extends AxiosRequestConfig {
	headers: AxiosRequestHeaders
	_retry?: boolean
}

axios.defaults.baseURL = process.env.REACT_APP_AXIOS_BASE_URL

export const setAuthToken = async (callback?: any) => {
	try {
		const accessToken = await getAccessToken()
		axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
		callback && callback()
	} catch (error) {
		handleLogout()
		console.error('Failed to acquire token silently. Error:', error)
	}
}

axios.interceptors.response.use(
	(response: AxiosResponse) => {
		return response
	},
	async (error: AxiosError) => {
		const originalRequest = error.config as AdaptAxiosRequestConfig
		if (error.response?.status === 401) {
			originalRequest._retry = true
			try {
				await setAuthToken()
				// return callApi(originalRequest)
			} catch (refreshError) {
				throw refreshError
			}
		}
		return Promise.reject(error)
	}
)

export {
	me,
	getProjects,
	getProjectById,
	getProjectOptions,
	createProject,
	getLightingOptions,
	cloneProject,
	deleteProject,
	recoverProject,
	updateProject,
	createOrUpdateRebateApplication,
	getProducts,
	createProduct,
	cloneProduct,
	deleteProduct,
	recoverProduct,
	updateProduct,
	getProductOptions,
	getLightingExchangeById,
	createLightingExchange,
	cloneLightingExchange,
	deleteLightingExchange,
	updateLightingExchange,
	getProjectStatus,
	createProjectStatus,
	updateProjectStatus,
	deleteProjectStatus,
	getCategories,
	createCategory,
	updateCategory,
	deleteCategory,
	getPaymentTerms,
	createPaymentTerms,
	updatePaymentTerms,
	deletePaymentTerms,
	getColourTemperatures,
	createColourTemperature,
	updateColourTemperature,
	deleteColourTemperature,
	getFixtures,
	createFixture,
	updateFixture,
	deleteFixture,
	getLamps,
	createLamp,
	updateLamp,
	deleteLamp,
	getTechnologies,
	createTechnology,
	updateTechnology,
	deleteTechnology,
	getVoltages,
	createVoltage,
	updateVoltage,
	deleteVoltage,
	getBaseTypes,
	createBaseType,
	updateBaseType,
	deleteBaseType,
	getHeatings,
	createHeating,
	updateHeating,
	deleteHeating,
	getSalesReps,
	createSalesRep,
	updateSalesRep,
	deleteSalesRep
}
