import moment from 'moment'

export const formatDate = (date: any, format?: string) => {
	if (date) {
		if (format) {
			return moment.utc(date).format(format)
		} else {
			return moment.utc(date).format('YYYY-MM-DD HH:mm')
		}
	}
	return ''
}
