import axios from 'axios'

export const me = async () => {
	try {
		const response = await axios.get('me')
		return response
	} catch (error: any) {
		console.error(error.response)
		return error
	}
}
