import React from 'react'
import { InputLabel, Typography } from '@mui/material'

interface Props {
	label: string
	value?: any
}

const AutoFilledField = ({ label, value }: Props) => {
	let parsedValue = value
	if (value === true) {
		parsedValue = 'Yes'
	}
	if (value === false) {
		parsedValue = 'No'
	}
	if (value === null || value === undefined) {
		parsedValue = '-'
	}

	return (
		<div>
			<InputLabel
				sx={{ fontSize: 14, fontWeight: 'bold', marginBottom: '4px' }}
			>
				{label}
			</InputLabel>
			<Typography>{parsedValue}</Typography>
		</div>
	)
}

export default AutoFilledField
