import React from 'react'
import {
	Table as MuiTable,
	TableBody,
	TableContainer,
	TableRow,
	TableFooter,
	Box,
	TableHead,
	TableSortLabel
} from '@mui/material'
import { PaginationState, Order } from 'models'
import TablePagination from './TablePagination'
import { getComparator, stableSort } from 'utils'
import TableCell from './TableCell'

interface TableProps {
	colSpan: number
	data: any
	count: number
	pagination: PaginationState
	setPagination: React.Dispatch<any>
	TableRowBuilder: any
	order: Order
	orderBy: any
	tableHeads: any[]
	handleRequestSort: any
	bulkSelect?: boolean
	dense?: boolean
}

const Table = ({
	colSpan,
	data,
	count,
	pagination,
	setPagination,
	TableRowBuilder,
	order,
	orderBy,
	tableHeads,
	handleRequestSort,
	bulkSelect = false,
	dense = false
}: TableProps) => {
	const emptyRows =
		pagination.page > 0
			? Math.max(0, (1 + pagination.page) * pagination.rowsPerPage - count)
			: 0

	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => {
		setPagination({
			index: 'page',
			value: newPage
		})
	}

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setPagination({
			type: 'SET_ROWS_PER_PAGE',
			value: parseInt(event.target.value, 10)
		})
	}

	const rows = React.useMemo(
		() => stableSort(data, getComparator(order, orderBy)),
		[data, order, orderBy]
	)

	const visibleRows =
		pagination.rowsPerPage > 0
			? rows.slice(
					pagination.page * pagination.rowsPerPage,
					pagination.page * pagination.rowsPerPage + pagination.rowsPerPage
			  )
			: rows

	return (
		<TableContainer component={Box}>
			<MuiTable aria-label='project table'>
				<TableHead>
					<TableRow>
						{bulkSelect && <TableCell padding='checkbox' />}
						{tableHeads.map((row: any, index: number) => {
							return (
								<TableCell
									dense={dense}
									key={`table_head-${index}`}
									align={row.align}
									width={row.width}
									sx={{ whiteSpace: 'nowrap' }}
								>
									{row.sortable ? (
										<TableSortLabel
											active={orderBy === row.id}
											direction={orderBy === row.id ? order : 'asc'}
											onClick={e => handleRequestSort(e, row.id)}
										>
											{row.name}
										</TableSortLabel>
									) : (
										row.name
									)}
								</TableCell>
							)
						})}
					</TableRow>
				</TableHead>
				<TableBody>
					{visibleRows.map((row: any, index: number) => (
						<TableRowBuilder key={`table_row_builder-${index}`} row={row} />
					))}
					{emptyRows > 0 && (
						<TableRow style={{ height: 53 * emptyRows }}>
							<TableCell dense={dense} colSpan={colSpan} />
						</TableRow>
					)}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TablePagination
							colSpan={colSpan}
							count={count}
							rowsPerPage={pagination.rowsPerPage}
							page={pagination.page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</TableRow>
				</TableFooter>
			</MuiTable>
		</TableContainer>
	)
}

export default Table
