import {
	Select as MuiSelect,
	FormControl,
	SelectProps,
	Typography,
	MenuItem,
	CircularProgress
} from '@mui/material'

type Props = SelectProps & {
	label: string
	children: React.ReactNode
	handleChange: any
	value: any
	isLoading?: boolean
	required?: boolean
	error?: any
	variant?: 'standard' | 'outlined' | 'filled'
}

const Select = ({
	label,
	children,
	handleChange,
	isLoading = false,
	value,
	error,
	required,
	...others
}: Props) => {
	return (
		<FormControl fullWidth error={error}>
			<Typography
				sx={{
					fontSize: 14,
					fontWeight: 'bold',
					color: error ? '#dc3545' : '#000'
				}}
			>
				{required === true ? `${label}*` : label}
			</Typography>
			<MuiSelect
				fullWidth
				size='small'
				sx={{ marginTop: '5px', marginBottom: '15px' }}
				onChange={(event: any) => {
					handleChange(event.target.value)
				}}
				value={isLoading ? '' : value}
				{...others}
			>
				{isLoading ? (
					<MenuItem
						value={0}
						sx={{ display: 'flex', justifyContent: 'center' }}
					>
						<CircularProgress />
					</MenuItem>
				) : (
					children
				)}
			</MuiSelect>
		</FormControl>
	)
}
export default Select
