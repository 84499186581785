import React from 'react'
import { FormControl, RadioGroup, FormLabel, RadioProps } from '@mui/material'

interface Props extends RadioProps {
	label: string
	children: React.ReactNode
	onChange: any
	value: any
	error?: any
	required?: boolean
}
const Radio = ({
	label,
	children,
	onChange,
	value,
	error,
	required
}: Props) => {
	return (
		<FormControl required={required} error={error}>
			<FormLabel sx={{ fontWeight: 'bold' }}>{label}</FormLabel>
			<RadioGroup row value={value} onChange={onChange}>
				{children}
			</RadioGroup>
		</FormControl>
	)
}

export default Radio
