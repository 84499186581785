import { loginRequest } from 'authConfig'
import { msalInstance } from 'index'

const getAccessToken = async () => {
	const account = msalInstance.getActiveAccount()
	if (!account) {
		throw Error(
			'No active account! Verify a user has been signed in and setActiveAccount has been called.'
		)
	}

	const response = await msalInstance.acquireTokenSilent({
		...loginRequest,
		account: account
	})
	return response.accessToken
}
const handleLogout = async () => {
	localStorage.clear()
	window.location.href = '/'
}
export { getAccessToken, handleLogout }
