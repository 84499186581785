import axios from 'axios'
import { Category } from 'models'

export const getCategories = async (searchTerm?: string) => {
	let url = '/category'
	if (searchTerm) {
		url += `?search=${encodeURIComponent(searchTerm)}`
	}
	const response = await axios.get(url)
	return response.data
}

export const createCategory = async (categoryData: Category) => {
	try {
		const response = await axios.post(`/category`, {
			...categoryData
		})
		return response.data
	} catch (error: any) {
		return error.response
	}
}

export const updateCategory = async (categoryData: any) => {
	try {
		const { id, ...rest } = categoryData
		const response = await axios.put(`/category/${id}`, {
			...rest
		})
		return response.data
	} catch (error: any) {
		return error.response
	}
}

export const deleteCategory = async (categoryId: number) => {
	try {
		const response = await axios.delete(`/category/${categoryId}`)
		return response.data
	} catch (error: any) {
		return error.response
	}
}
