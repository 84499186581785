import React, { ReactNode } from 'react'
import {
	Paper as MuiPaper,
	PaperProps,
	Box,
	BoxProps,
	Typography,
	Grid,
	styled
} from '@mui/material'

export interface Props {
	children: ReactNode
	title: string
	subtitle?: string
	color?: string
}

const StyledPaper = styled(MuiPaper, {
	shouldForwardProp: prop => prop !== 'color'
})<PaperProps>(({ color }) => ({
	alignItems: 'center',
	justifyContent: 'space-between',
	border: color ? `1px solid ${color}` : '1px solid #428bca',
	borderRadius: '4px',
	margin: '20px',
	padding: '0',
	backgroundColor: 'rgba(253, 253, 254, 0.8)',
	boxShadow: '0px 0px 40px 20px rgba(236, 235, 235, 0.25)',
	height: '100%',
	minHeight: '100%'
}))

const StyledBox = styled(Box, {
	shouldForwardProp: prop => prop !== 'color'
})<BoxProps>(({ color }) => ({
	backgroundImage:
		color === '#f2dede'
			? 'linear-gradient(to bottom,#f2dede 0,#ebcccc 100%)'
			: color === '#dff0d8'
			? 'linear-gradient(to bottom,#dff0d8 0,#d0e9c6 100%)'
			: 'linear-gradient(to bottom,#428bca 0,#357ebd 100%)',
	minHeight: '38px',
	borderBottom: color ? `1px solid ${color}` : '1px solid #428bca',
	borderTopLeftRadius: '3px',
	padding: '10px 15px'
}))

const Paper = ({ children, title, subtitle, color }: Props) => {
	return (
		<StyledPaper color={color ?? ''}>
			<StyledBox color={color ?? ''}>
				<Typography
					variant='h3'
					gutterBottom
					color={
						color === '#f2dede'
							? '#a94442'
							: color === '#dff0d8'
							? '#3c763d'
							: ''
					}
				>
					{title}
				</Typography>
				{subtitle && (
					<Typography variant='subtitle1' sx={{ color: 'white' }}>
						{subtitle}
					</Typography>
				)}
			</StyledBox>
			<Grid padding={'15px'}> {children} </Grid>
		</StyledPaper>
	)
}

export default Paper
