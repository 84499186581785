export const parseAddress = (address: string) => {
	if (address) {
		const regex =
			/(.+?)\s*,?\s*(AB|BC|MB|NB|NL|NT|NS|NU|ON|PE|QC|SK|YT)\s*,?\s*([A-Z]\d[A-Z]\s?\d[A-Z]\d)/

		const match = address.match(regex)
		if (match) {
			return match.splice(1)
		}
	}
	return [address]
}
