import {
	Project,
	ProjectAuditState,
	ProjectCreateState,
	LightingExchangeOptions,
	ProjectAnalysis,
	LightingExchangeCreateState,
	ProjectOptions,
	LightingExchange,
	RebateApplication
} from './project'
import { Product, ProductOptions } from './product'
import {
	ProjectStatus,
	Category,
	PaymentTerms,
	ColourTemperature,
	Fixture,
	Lamp,
	Technology,
	Voltage,
	BaseType,
	Heating,
	SalesRep
} from './lookup'

export enum UserPermission {
	SuperAdmin = 'super_admin',
	ProjectManager = 'project_manager',
	ViewAllProjects = 'view_all_projects',
	EditWarrantyStartDate = 'edit_warranty_start_date'
}

type User = {
	id: any
	fullName: string
	email: string
	deleted: any
	isAdmin: boolean
	phone: any
	permission?: UserPermission[]
} | null

type PaginationState = {
	page: number
	rowsPerPage: number
}

type Order = 'asc' | 'desc'

export type {
	User,
	PaginationState,
	Order,
	Project,
	ProjectAuditState,
	ProjectCreateState,
	LightingExchangeOptions,
	ProjectAnalysis,
	LightingExchangeCreateState,
	ProjectOptions,
	LightingExchange,
	Product,
	ProductOptions,
	ProjectStatus,
	Category,
	PaymentTerms,
	ColourTemperature,
	Fixture,
	Lamp,
	Technology,
	Voltage,
	BaseType,
	Heating,
	SalesRep,
	RebateApplication
}
