export const chunkWord = (word: string, size: any) => {
	const numChunks = Math.ceil(word.length / size)
	const chunks = new Array(numChunks)

	for (let i = 0, o = 0; i < numChunks; i++, o += size) {
		const chunk = word.substring(o, o + size)
		if (chunk.endsWith('-')) {
			chunks[i] = chunk.substring(0, chunk.length - 1)
		} else {
			chunks[i] = chunk
		}
	}
	return chunks
}
