import axios from 'axios'
import { BaseType } from 'models'

export const getBaseTypes = async (searchTerm?: string) => {
	let url = '/base-type'
	if (searchTerm) {
		url += `?search=${encodeURIComponent(searchTerm)}`
	}
	const response = await axios.get(url)
	return response.data
}

export const createBaseType = async (baseTypeData: BaseType) => {
	try {
		const response = await axios.post(`/base-type`, {
			...baseTypeData
		})
		return response.data
	} catch (error: any) {
		return error.response
	}
}

export const updateBaseType = async (baseTypeData: any) => {
	try {
		const { id, ...rest } = baseTypeData
		const response = await axios.put(`/base-type/${id}`, {
			...rest
		})
		return response.data
	} catch (error: any) {
		return error.response
	}
}

export const deleteBaseType = async (baseTypeId: number) => {
	try {
		const response = await axios.delete(`/base-type/${baseTypeId}`)
		return response.data
	} catch (error: any) {
		return error.response
	}
}
