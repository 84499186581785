import moment from 'moment'
import axios from 'axios'
import { RebateApplication } from 'models'
import {
	PDFDocument,
	PDFCheckBox,
	PDFRadioGroup,
	PDFTextField,
	PDFForm
} from 'pdf-lib'

const mapTextToBeforeYouBuy = (data: RebateApplication) => {
	return {
		'Business Name': data.businessName,
		'Business Contact Name': data.businessContactName,
		'Business Contact Title': data.businessContactName,
		Position: data.position,
		Phone: data.phone,
		Fax: data.fax,
		'Contact Email': data.email,
		'Mailing Address': data.address,
		CityTown: data.cityOrTown,
		'Postal Code': data.postalCode,
		'Address where products will be installed if different from above':
			data.installationAddress,
		CityTown_2: data.installationCityOrTown,
		'Postal Code_2': data.installationPostalCode,
		'Proposed Date of Installation': data.dateOfInstallation,
		'Company Installing Products': data.installerCompany,
		'Installer Contact Name and Title': data.installerNameOrTitle,
		'Installer Phone': data.installerPhone,
		'Installer Email': data.installerEmail,
		'Electricity Service Provider': data.electricityServiceProvider,
		'Contact Name on Utility Account': data.utilityContactName,
		'Utility Account Number': data.utilityAccountNumber,
		'Additional Meter Numbers': data.additionalMeterNumber,
		'Contact Title': data.businessContactName,
		'Phone Number': data.phone,
		'Email Address': data.email,
		Date: moment().format('YYYY-MM-DD'),
		'Text Field 83': data.mondayOpen,
		'Text Field 84': data.mondayClose,
		'Text Field 85': data.tuesdayOpen,
		'Text Field 86': data.tuesdayClose,
		'Text Field 87': data.wednesdayOpen,
		'Text Field 88': data.wednesdayClose,
		'Text Field 89': data.thursdayOpen,
		'Text Field 90': data.thursdayClose,
		'Text Field 91': data.fridayOpen,
		'Text Field 92': data.fridayClose,
		'Text Field 93': data.saturdayOpen,
		'Text Field 94': data.saturdayClose,
		'Text Field 95': data.sundayOpen,
		'Text Field 96': data.sundayClose,
		'Text Field 102': data.notes,
		'Text Field 109': data.seasonalShutdown,
		'Text Field 108': data.otherNonOperationalPeriods,
		'Text Field 1010': data.otherHeatingId
	}
}

const mapCheckboxToBeforeYouBuy = (data: RebateApplication) => {
	return {
		'I would like to receive email communications tips promotions etc from Efficiency Nova Scotia':
			data.emailUpdatesConsent,
		undefined_2: data.isNewConstruction,
		'Existing Building Retrofit': !data.isNewConstruction,
		'I agree to have the above installer contact copied on all email communications with Efficiency Nova Scotia':
			true,
		'I Agree': true,
		'This completed and signed rebate application': true,
		'An official detailed quote see invoice guidelines for whats required':
			true,
		'Completed worksheets for the products you are planning to install': true,
		'A copy of a recent electric bill': true,
		'By checking this box you acknowledge that youre filling this form out on behalf of your customer':
			true,
		'Check Box 47': !Boolean(data.isLargeFacility),
		'Check Box 48': data.nonOperationalPeriods === 'no',
		'Check Box 49': data.nonOperationalPeriods === 'holiday',
		'Check Box 50': data.nonOperationalPeriods === 'seasonal',
		'Check Box 52': data.heatingId === 'nonElectric',
		'Check Box 53': data.heatingId === 'other',
		'Check Box 54': Boolean(data.hasCoolingSystem),
		'Check Box 55': !Boolean(data.hasCoolingSystem),
		'Check Box 56': data.nonOperationalPeriods === 'other',
		'Check Box 46': Boolean(data.isLargeFacility),
		'Check Box 57': data.heatingId === 'noHeating',
		'Check Box 58': data.heatingId === 'airSourceHeatPump',
		'Check Box 59': data.heatingId === 'electricResistance'
	}
}

const mapRadioToBeforeYouBuy = (data: RebateApplication) => {
	const interestedInFinancing =
		data.interestedInFinancing === true
			? 'YES'
			: data.interestedInFinancing === false
			? 'NO'
			: ''
	return {
		undefined_4: interestedInFinancing
	}
}

const mapTextToAfterYouBuy = (data: RebateApplication) => {
	return {
		'Business Name': data.businessName,
		'Business Contact Name': data.businessContactName,
		Position: data.position,
		Phone: data.phone,
		Fax: data.fax,
		'Contact Email': data.email,
		'Mailing Address': data.address,
		CityTown: data.cityOrTown,
		'Postal Code': data.postalCode,
		'Address where products were installed if different from above':
			data.installationAddress,
		CityTown_2: data.installationCityOrTown,
		'Postal Code_2': data.installationPostalCode,
		'Date Products Installed': data.dateInstalled,
		'Company Installing Products': data.installerCompany,
		'Installer Contact Name and Title': data.installerNameOrTitle,
		'Installer Phone': data.installerPhone,
		'Installer Email': data.installerEmail,
		'Complete the information below to have your rebate sent to you as a chequeMake cheque payable to':
			data.chequePayableTo,
		'Mailing Address_2': data.chequeAddress,
		CityTown_3: data.chequeCityOrTown,
		'Postal Code_3': data.chequePostalCode,
		Name: data.businessContactName,
		Title: data.position,
		'Text Field 83': data.mondayOpen,
		'Text Field 84': data.mondayClose,
		'Text Field 85': data.tuesdayOpen,
		'Text Field 86': data.tuesdayClose,
		'Text Field 87': data.wednesdayOpen,
		'Text Field 88': data.wednesdayClose,
		'Text Field 89': data.thursdayOpen,
		'Text Field 90': data.thursdayClose,
		'Text Field 91': data.fridayOpen,
		'Text Field 92': data.fridayClose,
		'Text Field 93': data.saturdayOpen,
		'Text Field 94': data.saturdayClose,
		'Text Field 95': data.sundayOpen,
		'Text Field 96': data.sundayClose,
		'Text Field 102': data.notes,
		'Text Field 109': data.seasonalShutdown,
		'Text Field 108': data.otherNonOperationalPeriods,
		'Text Field 1010': data.otherHeatingId
	}
}

const mapCheckboxToAfterYouBuy = (data: RebateApplication) => {
	return {
		'CheckBox A': data.emailUpdatesConsent,
		'New Construction': data.isNewConstruction,
		'Existing Building Retrofit': !data.isNewConstruction,
		undefined_5: data.rebateType === 'directDeposit',
		undefined_6: data.rebateType === 'cheque',
		'Company Address': data.chequeAddressType === 'business',
		'Facility Address': data.chequeAddressType === 'facility',
		'Other please fill below': data.chequeAddressType === 'other',
		'I Agree': true,
		'This completed and signed rebate application': true,
		'An official detailed invoice see invoice guidelines MA2 for whats required':
			true,
		'Completed worksheets for the products you installed MA3': true,
		'A copy of a recent electric bill MA4': true,

		'Check Box 47': !Boolean(data.isLargeFacility),
		'Check Box 48': data.nonOperationalPeriods === 'no',
		'Check Box 49': data.nonOperationalPeriods === 'holiday',
		'Check Box 50': data.nonOperationalPeriods === 'seasonal',
		'Check Box 52': data.heatingId === 'nonElectric',
		'Check Box 53': data.heatingId === 'other',
		'Check Box 54': Boolean(data.hasCoolingSystem),
		'Check Box 55': !Boolean(data.hasCoolingSystem),
		'Check Box 56': data.nonOperationalPeriods === 'other',
		'Check Box 46': Boolean(data.isLargeFacility),
		'Check Box 57': data.heatingId === 'noHeating',
		'Check Box 58': data.heatingId === 'airSourceHeatPump',
		'Check Box 59': data.heatingId === 'electricResistance'
	}
}

const mapRadioToAfterYouBuy = (data: RebateApplication) => {
	const installAnyEnergyEfficientEquipment =
		data.installAnyEnergyEfficientEquipment === true
			? 'Yes'
			: data.installAnyEnergyEfficientEquipment === false
			? 'No'
			: ''
	const installLessEnergyEfficientEquipment =
		data.installLessEnergyEfficientEquipment === true
			? 'Yes_2'
			: data.installLessEnergyEfficientEquipment === false
			? 'No_2'
			: ''
	const postponeInstallation =
		data.postponeInstallation === true
			? 'Yes_3'
			: data.postponeInstallation === false
			? 'No_3'
			: ''
	return {
		undefined_2: installAnyEnergyEfficientEquipment,
		undefined_3: installLessEnergyEfficientEquipment,
		'Postpone the installation of equipment by more than two years':
			postponeInstallation
	}
}

const removeExtraPages = (pdfDoc: PDFDocument, lightingExchanges: any) => {
	const calculateLightingExchangePages = (lightingExchanges: any) => {
		if (lightingExchanges.length <= 11) return 1
		if (lightingExchanges.length <= 24) return 2
		if (lightingExchanges.length <= 37) return 3
		if (lightingExchanges.length <= 50) return 4
		if (lightingExchanges.length <= 63) return 5
		if (lightingExchanges.length <= 76) return 6
		if (lightingExchanges.length <= 89) return 7
		if (lightingExchanges.length <= 102) return 8
		if (lightingExchanges.length <= 115) return 9
		if (lightingExchanges.length <= 128) return 10
		if (lightingExchanges.length <= 141) return 11
		return 12
	}
	const defaultPageNumber = 3
	const exchangePageNumber = calculateLightingExchangePages(lightingExchanges)
	const totalPageNumber = defaultPageNumber + exchangePageNumber

	for (let i = pdfDoc.getPages().length - 1; i >= totalPageNumber; i--) {
		pdfDoc.removePage(i)
	}
}

const mapExchangesToPDF = (
	exchanges: any,
	form: PDFForm,
	pdfDoc: PDFDocument
) => {
	for (const [index, exchange] of exchanges.entries()) {
		const adjustedIndex = index + 1

		const area = form.getTextField(`area${adjustedIndex}`)
		const currentModel = form.getTextField(`currentModel${adjustedIndex}`)
		const newModel = form.getTextField(`newModel${adjustedIndex}`)
		const currentWattage = form.getTextField(`currentWattage${adjustedIndex}`)
		const newWattage = form.getTextField(`newWattage${adjustedIndex}`)
		const dlc = form.getTextField(`dlc${adjustedIndex}`)
		const currentQty = form.getTextField(`currentQty${adjustedIndex}`)
		const newQty = form.getTextField(`newQty${adjustedIndex}`)
		const weeklyRuntime = form.getTextField(`weeklyRuntime${adjustedIndex}`)
		const currentQuantity = String(
			exchange.currentTotalFixtures * exchange.currentLampsPerFixture
		)
		const newQuantity = String(
			exchange.newTotalFixtures * exchange.newLampsPerFixture
		)

		const currentModelInput =
			exchange.currentTechnologyId + ' ' + exchange.currentLamp?.name
		area.setText(exchange.section)
		currentModel.setText(currentModelInput)
		newModel.setText(exchange.newProduct.ModelNumber)
		currentWattage.setText(String(exchange.currentWattagePerLamp))
		newWattage.setText(String(exchange.newWattage))
		dlc.setText(exchange.newProduct.DlcNumber)
		currentQty.setText(String(currentQuantity))
		newQty.setText(String(newQuantity))
		weeklyRuntime.setText(String(exchange.newTotalWeeklyRuntime))

		const winterAfterHoursYes = form.getCheckBox(
			`winterAfterHoursYes${adjustedIndex}`
		)
		const winterAfterHoursNo = form.getCheckBox(
			`winterAfterHoursNo${adjustedIndex}`
		)
		const dimmableYes = form.getCheckBox(`dimmableYes${adjustedIndex}`)
		const dimmableNo = form.getCheckBox(`dimmableNo${adjustedIndex}`)

		if (exchange.winterAfterHours) {
			winterAfterHoursYes.check()
		} else {
			winterAfterHoursNo.check()
		}
		if (exchange.newProduct.dimmable) {
			dimmableYes.check()
		} else {
			dimmableNo.check()
		}
	}
	removeExtraPages(pdfDoc, exchanges)
}

const processFields = (map: any, form: PDFForm) => {
	Object.keys(map).forEach(fieldName => {
		const field = form.getField(fieldName)
		if (field instanceof PDFTextField) {
			// @ts-ignore
			field.setText(map[fieldName])
		}
		if (field instanceof PDFCheckBox) {
			// @ts-ignore
			map[fieldName] ? field.check() : field.uncheck()
		}
		if (field instanceof PDFRadioGroup) {
			// @ts-ignore
			const value = map[fieldName]
			value === '' ? field.clear() : field.select(value)
		}
	})
}

export const fillAndDownloadRebateApplication = async (
	type: 'before' | 'after',
	data: RebateApplication,
	lightingExchanges: any
) => {
	let pdfUrl = ''
	let mapToBeforeYouBuy: any = {}
	let mapToAfterYouBuy: any = {}
	let fileName = ''

	if (type === 'before') {
		const textData = mapTextToBeforeYouBuy(data)
		const checkboxData = mapCheckboxToBeforeYouBuy(data)
		const radioData = mapRadioToBeforeYouBuy(data)
		mapToBeforeYouBuy = { ...textData, ...checkboxData, ...radioData }
		fileName = 'Before you Buy Rebate application.pdf'
		pdfUrl =
			'https://nichent-image.s3.ca-central-1.amazonaws.com/Before+you+Buy+Rebate+application.pdf'
	}
	if (type === 'after') {
		const textData = mapTextToAfterYouBuy(data)
		const checkboxData = mapCheckboxToAfterYouBuy(data)
		const radioData = mapRadioToAfterYouBuy(data)
		mapToAfterYouBuy = { ...textData, ...checkboxData, ...radioData }
		fileName = 'BER After You Buy Rebate application.pdf'
		pdfUrl =
			'https://nichent-image.s3.ca-central-1.amazonaws.com/BER+After+You+Buy+Rebate+application.pdf'
	}

	const response = await axios.get(pdfUrl, {
		responseType: 'arraybuffer',
		headers: { Authorization: undefined }
	})
	const existingPdfBytes = response.data
	const pdfDoc = await PDFDocument.load(existingPdfBytes)
	const form = pdfDoc.getForm()

	if (type === 'before') {
		processFields(mapToBeforeYouBuy, form)
	} else if (type === 'after') {
		processFields(mapToAfterYouBuy, form)
	}
	mapExchangesToPDF(lightingExchanges, form, pdfDoc)
	const pdfBytes = await pdfDoc.save()
	const blob = new Blob([pdfBytes], { type: 'application/pdf' })
	const link = document.createElement('a')
	link.href = URL.createObjectURL(blob)
	link.download = fileName
	link.click()
}
