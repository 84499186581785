import axios from 'axios'
import { ProjectStatus } from 'models'

export const getProjectStatus = async (searchTerm?: string) => {
	let url = '/project-status'
	if (searchTerm) {
		url += `?search=${encodeURIComponent(searchTerm)}`
	}
	const response = await axios.get(url)
	return response.data
}

export const createProjectStatus = async (projectStatusData: ProjectStatus) => {
	try {
		const response = await axios.post(`/project-status`, {
			...projectStatusData
		})
		return response.data
	} catch (error: any) {
		return error.response
	}
}

export const updateProjectStatus = async (projectStatusData: any) => {
	try {
		const { id, ...rest } = projectStatusData
		const response = await axios.put(`/project-status/${id}`, {
			...rest
		})
		return response.data
	} catch (error: any) {
		return error.response
	}
}

export const deleteProjectStatus = async (projectStatusId: string) => {
	try {
		const response = await axios.delete(`/project-status/${projectStatusId}`)
		return response.data
	} catch (error: any) {
		return error.response
	}
}
