import axios from 'axios'
import { Voltage } from 'models'

export const getVoltages = async (searchTerm?: string) => {
	let url = '/voltage'
	if (searchTerm) {
		url += `?search=${encodeURIComponent(searchTerm)}`
	}
	const response = await axios.get(url)
	return response.data
}
export const createVoltage = async (voltageData: Voltage) => {
	try {
		const response = await axios.post(`/voltage`, {
			...voltageData
		})
		return response.data
	} catch (error: any) {
		return error.response
	}
}

export const updateVoltage = async (voltageData: any) => {
	try {
		const { id, ...rest } = voltageData
		const response = await axios.put(`/voltage/${id}`, {
			...rest
		})
		return response.data
	} catch (error: any) {
		return error.response
	}
}

export const deleteVoltage = async (voltageId: string) => {
	try {
		const response = await axios.delete(`/voltage/${voltageId}`)
		return response.data
	} catch (error: any) {
		return error.response
	}
}
