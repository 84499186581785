import axios from 'axios'
import { SalesRep } from 'models'

export const getSalesReps = async (searchTerm?: string) => {
	let url = '/users'
	if (searchTerm) {
		url += `?search=${encodeURIComponent(searchTerm)}`
	}
	const response = await axios.get(url)
	return response
}

export const createSalesRep = async (salesRepData: SalesRep) => {
	try {
		const response = await axios.post(`/users`, {
			...salesRepData
		})
		return response
	} catch (error: any) {
		return error.response
	}
}

export const updateSalesRep = async (salesRepData: any) => {
	try {
		const { id, ...rest } = salesRepData
		const response = await axios.put(`/users/${id}`, {
			...rest
		})
		return response
	} catch (error: any) {
		return error.response
	}
}

export const deleteSalesRep = async (salesRepId: number) => {
	try {
		const response = await axios.delete(`/users/${salesRepId}`)
		return response
	} catch (error: any) {
		return error.response
	}
}
