import { ProjectAnalysis, LightingExchange } from 'models'

export const getEnergyReduction = (data: ProjectAnalysis) => {
	const totalEnergyReduction = data.currentAnnualEnergy - data.newAnnualEnergy
	const co2TonsEliminated = (totalEnergyReduction * 0.3712) / 1000.0
	const passengerVehiclesEliminated = co2TonsEliminated / 4.6

	return {
		passengerVehiclesEliminated,
		co2TonsEliminated
	}
}

export const getNewproduct = (lightingExchanges: LightingExchange[]) => {
	const aggregateNewProducts = (lightingExchangeData: any[]) =>
		lightingExchangeData?.reduce((prev, curr) => {
			const newProduct = curr.newProduct
			const existing = prev.find((p: any) => p.id === newProduct.id)
			const currQuantity = curr.newTotalFixtures * curr.newLampsPerFixture
			if (existing) {
				existing.quantity += currQuantity
			} else {
				prev.push({
					id: newProduct.id,
					description: newProduct.description,
					Brand: newProduct.Brand,
					ModelNumber: newProduct.ModelNumber,
					DlcNumber: newProduct.DlcNumber,
					price: newProduct.price,
					listPrice: curr.newListPrice,
					quantity: currQuantity
				})
			}
			return prev
		}, [] as any[])
	const newProducts = aggregateNewProducts(lightingExchanges)
	return newProducts
}

export const getSectionedLightingExchanges = (
	lightingExchanges: LightingExchange[]
) => {
	const aggregateBySection = (lightingExchangeData: any[]) =>
		lightingExchangeData?.reduce((prev, curr) => {
			const existing = prev.find((p: any) => p.section === curr.section)
			if (existing) {
				existing.ids.push(curr.id)
				existing.lightingExchanges.push(curr)
			} else {
				prev.push({
					section: curr.section,
					ids: [curr.id],
					lightingExchanges: [curr]
				})
			}
			return prev
		}, [] as any[])
	const sectionedLightingExchanges = aggregateBySection(lightingExchanges)
	return sectionedLightingExchanges
}

// ARCHIVED const hasMatchingBeforeAndAfterProducts = (
// 	existingExchange: AreaLightingExchange,
// 	currentExchange: LightingExchange
// ): boolean => {
// 	return (
// 		existingExchange.currentLampId === currentExchange.currentLampId &&
// 		existingExchange.currentTechnologyId ===
// 			currentExchange.currentTechnologyId &&
// 		existingExchange.newProductId === currentExchange.newProductId
// 	)
// }

// const getAreaKey = (exchange: LightingExchange): number => {
// 	const hashCode = (str: string): number => {
// 		let hash = 0
// 		for (let i = 0; i < str.length; i++) {
// 			const character = str.charCodeAt(i)
// 			hash = (hash << 5) - hash + character
// 			hash = hash & hash // Convert to 32bit integer
// 		}
// 		return hash
// 	}

// 	const aggr = `${exchange.Seasonal}${exchange.heating.id}${exchange.CoolingSystem}${exchange.winterAfterHours}${exchange.newTotalWeeklyRuntime}`
// 	return hashCode(aggr)
// }

// export const getEfficiencyNSAreas = (lightingExchanges: LightingExchange[]) => {
// 	const areaMap: Map<number, Area> = new Map()

// 	lightingExchanges.forEach((exchange: LightingExchange) => {
// 		const key = getAreaKey(exchange)
// 		const currentQuantity =
// 			exchange.currentTotalFixtures * exchange.currentLampsPerFixture
// 		const newQuantity = exchange.newTotalFixtures * exchange.newLampsPerFixture

// 		if (areaMap.has(key)) {
// 			let addNew = true
// 			areaMap
// 				.get(key)!
// 				.exchanges.forEach((areaExchange: AreaLightingExchange) => {
// 					if (hasMatchingBeforeAndAfterProducts(areaExchange, exchange)) {
// 						areaExchange.currentTotalQuantity += currentQuantity
// 						areaExchange.newTotalQuantity += newQuantity
// 						addNew = false
// 					}
// 				})

// 			if (addNew) {
// 				const newExchange = {
// 					...exchange,
// 					currentTotalQuantity: currentQuantity,
// 					newTotalQuantity: newQuantity
// 				} as AreaLightingExchange

// 				areaMap.get(key)!.exchanges!.push(newExchange)
// 			}

// 			if (!areaMap.get(key)!.sectionNames!.includes(exchange.section)) {
// 				areaMap.get(key)!.sectionNames!.push(exchange.section)
// 			}
// 		} else {
// 			const area: Area = {
// 				sectionNames: [exchange.section],
// 				exchanges: [
// 					{
// 						...exchange,
// 						currentTotalQuantity: currentQuantity,
// 						newTotalQuantity: newQuantity
// 					}
// 				],
// 				seasonal: Boolean(exchange.Seasonal),
// 				coolingSystem: exchange.CoolingSystem,
// 				heatingName: exchange.heating.name,
// 				winterAfterHours: exchange.winterAfterHours,
// 				totalWeeklyRuntime: exchange.newTotalWeeklyRuntime
// 			}

// 			areaMap.set(key, area)
// 		}
// 	})
// 	return Array.from(areaMap.values())
// }
