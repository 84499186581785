import {
	styled,
	TableCell as MuiTableCell,
	TableCellProps
} from '@mui/material'

interface StyledTableCellProps extends TableCellProps {
	dense?: boolean
}

const TableCell = styled(MuiTableCell, {
	shouldForwardProp: prop => prop !== 'dense' && prop !== 'hovered'
})<StyledTableCellProps>(({ dense }) => ({
	padding: dense ? '4px 8px' : 'inherit'
}))

export default TableCell
